import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import Homepage from './homepage';
import Compare from './compare';
import { PageWrapper, PageMain } from './components/styles/common.styled';
import './App.css';

declare module '@mui/material/Button/Button' {
	interface ButtonPropsVariantOverrides {
		'contained-secondary': true;
	}
}

const globalTheme = createTheme({
	palette: {
		primary: {
			main: '#F68D2E',
			dark: '#F68D2E',
			light: '#FFC72C'
		},
		secondary: {
			main: '#1F378C',
			dark: '#071D49',
			light: '#1F378C'
		}
	}
})

const theme = createTheme({
	components: {
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					backgroundColor: '#fff'
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					backgroundColor: '#fff'
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				outlined: {
					backgroundColor: '#fff'
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 6,
					fontFamily: 'VarelaRound',
					color: '#fff',
					boxShadow: '0 2px 4px 0 rgb(43 39 55 / 8%), 0 4px 8px 0 rgb(43 39 55 / 8%)',
					textTransform: 'inherit'
				},
				sizeLarge: {
					fontSize: 17,
					padding: '16px 40px'
				},
				sizeMedium: {
					padding: '12px 20px'
				}
			},
			variants: [{
				props: { variant: 'contained' },
				style: {
					'&:hover': {
						backgroundColor: globalTheme.palette.primary.light
					}
				}
			}, {
				props: { variant: 'outlined' },
				style: {
					boxShadow: 'none',
					backgroundColor: '#fff',
					border: `2px solid ${globalTheme.palette.secondary.main}`,
					color: globalTheme.palette.secondary.main,
					'&:hover': {
						border: `2px solid ${globalTheme.palette.primary.main}`,
						color: globalTheme.palette.primary.main
					}
				}
			}, {
				props: { variant: 'contained-secondary' },
				style: {
					backgroundColor: globalTheme.palette.secondary.main,
					boxShadow: `0px 0px 0px 2px ${globalTheme.palette.secondary.main} inset`,
					color: '#fff',
					'&:hover': {
						backgroundColor: '#fff',
						boxShadow: `0px 0px 0px 2px ${globalTheme.palette.primary.main} inset`,
						color: globalTheme.palette.primary.main
					}
				}
			}, {
				props: { variant: 'text' },
				style: {
					backgroundColor: 'transparent',
					boxShadow: 'none',
					padding: 0,
					'&:hover': {
						backgroundColor: 'transparent'
					}
				}
			}]
		}
	}
}, globalTheme);

function App() {
	return (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				<PageMain>
					<Header />
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Homepage />} />
							<Route path="/compare" element={<Compare />} />
						</Routes>
					</BrowserRouter>
				</PageMain>
				<Footer />
			</PageWrapper>
		</ThemeProvider>
	);
}

export default App;
