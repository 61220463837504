import { StyledFooter, FooterSocialLinks, FooterCopyright } from './styles/common.styled';
import moment from 'moment';
import { Container } from '@mui/system';
import { Grid } from '@mui/material';

export const Footer = () => {
    return (
        <>
            <StyledFooter>
                <Container>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} sm={6} textAlign={{xs:'center',sm:'left'}}>
                            <img src="/images/logo-footer-2x.png" width="210" alt="" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FooterSocialLinks>
                                <a href="https://www.facebook.com/Value-Power-257830322779761" target="_blank" rel="noopener noreferrer"><img src="/images/icon-facebook-2x.png" width="36" alt="" /></a>
                                <a href="https://www.instagram.com/valuepowertx/" target="_blank" rel="noopener noreferrer"><img src="/images/icon-instagram-2x.png" alt="" width="36" /></a>
                            </FooterSocialLinks>
                        </Grid>
                    </Grid>
                </Container>
            </StyledFooter>
            <FooterCopyright>
                <Container>
                    <Grid container>
                        <Grid item xs={12} sm={6} textAlign={{xs:'center',sm:'left'}}>
                            <a href="https://myvaluepower.com/sitemap">Site Map</a>|
                            <a href="https://myvaluepower.com/terms-of-use">Terms of Use</a>|
                            <a href="https://myvaluepower.com/privacy">Privacy Policy</a>|
                            <a href="https://myvaluepower.com/admin">Admin</a>
                        </Grid>
                        <Grid item xs={12} sm={6} textAlign={{xs:'center',sm:'right'}}>
                            © {moment().format('YYYY')} Value Power PUCT # 10293. All Rights 
                        </Grid>
                    </Grid>
                </Container>
            </FooterCopyright>
        </>
    )
}