import { useState } from "react";
import {
    StyledHeader, Logo, StyledHeaderInner,
    HeaderMenu, HeaderMenuItem, MenuToggle
} from "./styles/common.styled";
import { Button } from '@mui/material';

export const Header = () => {
    const [ showMenu, setShowMenu ] = useState(false);

    return (
        <StyledHeader>
            <StyledHeaderInner>
                <Logo href="/">
                    <img src="/images/logo.png" alt="" style={{marginRight:20}} />
                    <span>Side-by-side</span>
                </Logo>
                <MenuToggle
                    active={`${showMenu}`}
                    onClick={() => {
                        if (showMenu) {
                            document.body.style.overflow = 'unset'
                        }
                        else {
                            document.body.style.overflow = 'hidden'
                        }
                        setShowMenu(!showMenu)
                    }}
                />
                <HeaderMenu active={`${showMenu}`}>
                    <HeaderMenuItem href="https://myvaluepower.com/pricing-plans">Pricing &amp; Plans</HeaderMenuItem>
                    <HeaderMenuItem href="https://myvaluepower.com/residential">Residential</HeaderMenuItem>
                    <Button size="medium" variant="contained" onClick={() => {
                        window.location.href = "https://myvaluepower.com/pricing-plans"
                    }}>Enroll Now</Button>
                    <Button size="medium" variant="contained-secondary" onClick={() => {
                        window.location.href = "https://valuepower.myaccount.energy/sigma/myeaccount/?connectName=SigmaVPower"
                    }}>My Account</Button>
                </HeaderMenu>
            </StyledHeaderInner>
        </StyledHeader>
    )
}