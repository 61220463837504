export const REPs = [
    {
        providerId: '258',
        providerName: 'Value Power',
        host: true
    },
    {
        providerId: '163',
        providerName: 'Direct Energy'
    },
    {
        providerId: '176',
        providerName: 'TXU Energy'
    },
    {
        providerId: '159',
        providerName: 'Reliant'
    },
    {
        providerId: '206',
        providerName: 'Green Mountain'
    },
    {
        providerId: '183',
        providerName: 'Champion Energy'
    }
];

export const API_KEY = '6b9ec83c86f934c4260ddf30fd542cd7';
export const API_URL = 'https://bb0xg7o51l.execute-api.us-east-2.amazonaws.com/beta';
export const POWERLEGO_API_URL = 'https://www.powerlego.com/ApiGateway';
export const GOOGLE_API_KEY = 'AIzaSyCsreMGj8xStaCAs9V2HjMizheuJaVlII0';